.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-link-div {
  background-color: #181c24;
  border: 1px solid gray;
  padding: 10%;
  border-radius: 2vw;
  font-size: 2vw;
  color: white;
  text-decoration: none ;
  margin-top: 0.5em;
}

.disabled {
  background-color: #383c44;
}

.rcp-fields-element-input {
  display: none;
}

.rcp-body {
  width: 80%;
}

.page-link-group {
  display: inline-block;
}

.page-title {
  position: absolute;
  top: 5vw;
  width: 95%;
  text-align: left;
}

.page-link-div {
  width: 100%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  font-size: 3vw;
  border-radius: 4vw
}

@media (max-width: 1000px) {
  .page-link-div {
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 5vw;
    padding-bottom: 5vw;
    font-size: 5vw;
    border-radius: 4vw
  }

  .page-link-group {
    display: block;
    width: 95%;
  }
}

a {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.off-button {
  background-color: #181c24;
  border: 1px solid gray;
  padding: 3px;
  width: 20vw;
  border-radius: 2vw;
  color: white;
  text-decoration: none ;

}
